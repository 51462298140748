import React, {
  useRef, useEffect, useCallback, window
} from 'react';
import { string, func, bool, instanceOf } from 'prop-types';
import {
  Button,
  CardActions,
  CardBody,
  CardContent,
  CardMedia,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
  useBreakpoint,
  Rating as RatingComponent
} from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { useImageOverlay } from '../hooks/useImageOverlay';

const interactionTypes = {
  CLICK_NEXT: 'main-img-click-next',
  CLICK_PREVIOUS: 'main-img-click-previous',
  SWIPE_NEXT: 'main-img-swipe-next',
  SWIPE_PREVIOUS: 'main-img-swipe-previous',
  THUMBNAIL_CLICK: 'thumbnail-img-click',
};

const CustomerImageOverlay = (props) => {
  const {
    isFromCarousel,
    channel,
    goBack,
    isQuestionsAndAnswerPhotos,
    loopPhotos,
    onClose,
    onInteraction,
    overlaySource,
    photos,
    productLabel,
    question,
    selectedPhotoID,
    answer
  } = props;

  const handleClose = () => onClose();
  if (goBack) {
    window.history.back();
  }
  const {
    hasNext,
    hasPrevious,
    selected,
    selectNext,
    selectPrevious,
    customerResultIndex,
    Results
  } = useImageOverlay(photos, selectedPhotoID, loopPhotos, isQuestionsAndAnswerPhotos, question);
  const screen = useBreakpoint('lg');

  const isDesktop = channel === 'desktop';

  const { review, photo } = selected;
  const customerResults = Results.length;
  const {
    Title, Rating, UserNickname, SubmissionTime, ReviewText, Photos
  } = review;
  const { AnswerText, UserNickname: username, LastModificationTime } = answer;
  const { Sizes: { normal: { Url } }, Id } = photo;
  const parsedDate = new Date(Date.parse(SubmissionTime || LastModificationTime));
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const displayDate = parsedDate.toLocaleDateString('en-US', options);
  const reviewPhotos = Photos.map((picture) => {
    return {
      photoId: picture?.Id,
      url: picture?.Sizes?.normal?.Url
    };
  });

  const thumbnailsRef = useRef();

  const scrollIntoView = () => {
    thumbnailsRef?.current?.querySelector?.('.highlighted-image')?.scrollIntoView?.();
  };
  const interactionHandler = (interactionType) => {
    try {
      onInteraction(interactionType);
      // eslint-disable-next-line no-empty
    } catch (error) {
      // TODO: what should happen if we get an error here?
    }
  };
  const previousButtonClickHandler = () => {
    selectPrevious();
    interactionHandler(interactionTypes.CLICK_PREVIOUS);
  };

  const nextButtonClickHandler = () => {
    selectNext();
    interactionHandler(interactionTypes.CLICK_NEXT);
  };

  const navigateWithKeyboard = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 37) {
      previousButtonClickHandler();
    } else if (keyCode === 39) {
      nextButtonClickHandler();
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle?.trigger('customer-image-overlay.ready');
  }, []);

  useEffect(() => {
    if (isDesktop) {
      scrollIntoView();
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', navigateWithKeyboard);
    return () => {
      document.removeEventListener('keydown', navigateWithKeyboard);
    };
  }, [navigateWithKeyboard]);

  const isRatingsAndReviews = overlaySource === 'ratingsAndReviews';
  const shouldShowProductLabel = !!productLabel;
  const shouldShowButtons = isFromCarousel && isRatingsAndReviews;
  let shouldShowButtonsAbove = false;
  let shouldShowButtonsBelow = false;
  const shouldShowFooter = shouldShowProductLabel || shouldShowButtonsBelow;
  const swiperMargin = isDesktop ? '0px' : '8px';

  let orientation;
  let size = 'md';

  if (screen.lesser) {
    orientation = 'vertical';
    size = 'lg';
    shouldShowButtonsBelow = shouldShowButtons;
  } else {
    orientation = 'horizontal';
    size = 'md';
    shouldShowButtonsAbove = shouldShowButtons;
  }

  const renderCardActions = () => {
    return (
      <CardActions data-testid="action-buttons" position="start">
        <Button
          variant="secondary"
          onClick={previousButtonClickHandler}
          disabled={hasPrevious}
          data-testid="arrow-previous"
          fullWidth={!isDesktop}
        >
          {isRatingsAndReviews ? 'Previous Review' : 'Previous Question'}
        </Button>
        <div className="sui-mx-2" />
        <Button
          onClick={nextButtonClickHandler}
          disabled={hasNext}
          data-testid="arrow-next"
          fullWidth={!isDesktop}
        >
          {isRatingsAndReviews ? 'Next Review' : 'Next Question'}
        </Button>
      </CardActions>
    );
  };

  return (
    <Modal open onClose={handleClose} data-testid="modal" size={size}>
      <ModalHeader className="sui-border-black sui-border-2" onClose={handleClose}>
        {isRatingsAndReviews ? (
          <>Customer Reviews ({customerResultIndex + 1} of {customerResults})</>
        ) : (
          <>Customer Questions ({customerResultIndex + 1} of {customerResults})</>
        )}
      </ModalHeader>
      <ModalBody className="sui-w-full">
        <CardContent orientation={orientation} disableGutters disablePadding disableShrink>
          <CardContent disablePadding="y" initialSize="2/4">
            <Carousel
              type="hero"
              disableShadow
              hidePaginationOnOneOfOne
              data-testid="carousel"
              SwiperProps={{ style: { marginBottom: swiperMargin } }}
            >
              {
                reviewPhotos.map(({ photoId, url }) => {
                  return (
                    <div
                      key={photoId}
                      data-testid={photoId}
                    >
                      <CardMedia
                        src={url}
                        width="fit"
                        aspect="square"
                      />
                    </div>
                  );
                })
              }
            </Carousel>
          </CardContent>
          <CardContent disablePadding="y" initialSize="2/4">
            <CardBody>
              {Title
                && (
                  <Typography
                    height="tight"
                    variant="h3"
                  >{Title}
                  </Typography>
                )}
              {Rating && <RatingComponent color="brand" size="small" value={Rating} />}
              {question && (
                <Typography
                  height="tight"
                  variant="h3"
                >{question}
                </Typography>
              )}
              {(UserNickname || username) && displayDate
              && (
                <Typography color="subtle">By
                  <span
                    className="sui-underline sui-px-1 sui-pb-1"
                  >{UserNickname || username},
                  </span>
                  {displayDate}
                </Typography>
              )}
              <div className="sui-mt-4">
                <Typography>
                  {ReviewText || AnswerText}
                </Typography>
              </div>
            </CardBody>
            {shouldShowButtonsAbove && renderCardActions()}
          </CardContent>
        </CardContent>
      </ModalBody>
      {shouldShowFooter && (
        <ModalFooter data-testid="modal-footer" position="center">
          <div className="sui-flex-col">
            {shouldShowButtonsBelow && (
              <div className="sui-mb-4">
                {renderCardActions()}
              </div>
            )}
            {shouldShowProductLabel && (
              <Typography variant="body-xs">This review is from {productLabel}</Typography>
            )}
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

CustomerImageOverlay.propTypes = {
  isFromCarousel: bool,
  channel: string,
  goBack: bool,
  isQuestionsAndAnswerPhotos: bool,
  loopPhotos: bool,
  onClose: func.isRequired,
  onInteraction: func,
  overlaySource: string,
  photos: instanceOf(Object),
  productLabel: string,
  question: string,
  answer: instanceOf(Object),
  selectedPhotoID: string
};

CustomerImageOverlay.defaultProps = {
  isFromCarousel: true,
  channel: 'desktop',
  goBack: false,
  isQuestionsAndAnswerPhotos: false,
  loopPhotos: false,
  onInteraction: () => {},
  overlaySource: null,
  photos: {},
  productLabel: null,
  question: '',
  answer: {},
  selectedPhotoID: null
};

export { CustomerImageOverlay, interactionTypes };